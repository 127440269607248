import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/Home";
import RegisterPage from "./pages/Register";
import LoginPage from "./pages/Login";
import ConfirmPage from "./pages/Confirm";
import ConfirmByCodePage from "./pages/ConfirmByCode";
import ForgotPasswordPage from "./pages/ForgotPassword";
import NewPasswordPage from "./pages/NewPassword";

const router = createBrowserRouter([
    { path: "/", element: <HomePage /> },
    { path: "/register", element: <RegisterPage /> },
    { path: "/login", element: <LoginPage /> },
    { path: "/confirm/:username/:code", element: <ConfirmPage />},
    { path: "/confirmByCode/:username", element: <ConfirmByCodePage /> },
    { path: "/forgotPassword", element: <ForgotPasswordPage /> },
    { path: "/newPassword/:username/:code", element: <NewPasswordPage /> },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
