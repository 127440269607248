import logo from "../images/logo.png";
import styles from "./Register.module.css";
import {MdEmail, MdLock} from "react-icons/md";
import {CognitoUserAttribute, CognitoUserPool} from "amazon-cognito-identity-js";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID
}

console.info(poolData)

const userPool = new CognitoUserPool(poolData);

function extractUserName(email) {
    const indexAt = email.indexOf("@");
    return email.substring(0, indexAt);
}

const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    function signUp(e) {
        e.preventDefault();
        const attributeList = [
            new CognitoUserAttribute({Name: 'email', Value: email})
        ];

        const username = extractUserName(email);

        userPool.signUp(username, password, attributeList, null, (err, result) => {
            if (err) {
                console.error('Error signing up', err);
                setErrorMessage(JSON.stringify(err));
                return;
            }
            console.info('User registered successfully', result.user);
            navigate(`/confirmByCode/${username}`);
        });
    }

    function changeEmailHandler (e) {
        setEmail(e.target.value);
    }

    function changePasswordHandler(e) {
        setPassword(e.target.value);
    }

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <img src={logo} alt="Hero" className={styles.logo} />
                <h1 className={styles.title}>Registro</h1>
                <form onSubmit={signUp}>
                    <div className={styles.email}>
                        <MdEmail className={styles.icon} />
                        <input
                            type="email"
                            placeholder="usuario@correo.com"
                            autoFocus
                            onChange={changeEmailHandler}
                            required
                        />
                    </div>
                    <div className={styles.email}>
                        <MdLock className={styles.icon} />
                        <input type="password" placeholder="**********" onChange={changePasswordHandler} required/>
                    </div>
                    <button className={styles.button} type='submit'>Registrar</button>
                    <Link to="/login" className={styles.link}>Ya tengo una cuenta</Link>
                    <p className={styles.error}>{errorMessage}</p>
                </form>
            </div>
        </div>
    );
};

export default RegisterPage;
