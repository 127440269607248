import styles from "./Home.module.css";
import { Fragment } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const HomePage = () => {
    return (
        <Fragment>
            <header className={styles.header}>
                <nav className={styles.nav}>
                    <ul className={styles.menu}>
                        <li className={`${styles.menu_item}`}>
                            <Link className={styles.link} to="/register">Registro</Link>
                        </li>
                        <li className={`${styles.menu_item}`}>
                            <Link className={styles.link} to="/login">Ingreso</Link>
                        </li>
                    </ul>
                </nav>
                <img src={logo} alt="Hero" className={styles.hero_image} />
                <p className={styles.hero_text}>
                    La plataforma que estabas buscando para tu negocio
                </p>
                <p className={styles.hero_info}>
                    Estamos trabajando para brindarte la mejor experiencia en la
                    gestión de tu negocio. Pronto tendremos más noticias.
                </p>
            </header>
            <main></main>
        </Fragment>
    );
};
export default HomePage;
