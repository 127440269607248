import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from './Confirm.module.css';
import logo from "../images/logo.png";


const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID
}

const userPool = new CognitoUserPool(poolData);

const ConfirmPage = () => {
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const params = useParams();

    useEffect( () => {
        const userData = {
            Username: params.username,
            Pool: userPool
        }
        const cognitoUser = new CognitoUser(userData);
        function confirmUser(){

            cognitoUser.confirmRegistration(params.code,true, (err, result) => {
                if (err) {
                    setMessage(JSON.stringify(err))
                    return;
                }
                setSuccess(true);
                setMessage(JSON.stringify(result))
            })
        }
        confirmUser();
    }, [params.code, params.username])

    return <div className={styles.container}>
        {!success && <div className={styles.card}>
            <img src={logo} alt="Hero" className={styles.logo} />
            <h1 className={styles.title}>¡UPS!</h1>
            <p className={styles.message}>Ha ocurrido un error: ${message}</p>
            <Link to="/" className={styles.button}>Retornar</Link>
        </div>}
        {success && <div className={styles.card}>
            <img src={logo} alt="Hero" className={styles.logo} />
            <h1 className={styles.title}>¡Bienvenido!</h1>
            <p className={styles.message}>Se ha confirmado tu correo electrónico. Para poder ingresar al sistema, haz click en el botón de abajo.</p>
            <Link to="/login" className={styles.button}>Ingresar</Link>
        </div>}
    </div>

};

export default ConfirmPage;
