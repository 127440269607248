import styles from './Login.module.css';
import logo from "../images/logo.png";
import {MdEmail, MdLock} from "react-icons/md";
import { useState } from "react";
import { Link } from "react-router-dom";

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const changeEmailHandler = (e) => {
        setEmail(e.target.value);
    }

    const changePasswordHandler = (e) => {
        setPassword(e.target.value);
    }

    const login = () => {
        console.log(email);
        console.log(password);
    }

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <img src={logo} alt="Hero" className={styles.logo} />
                <h1 className={styles.title}>Ingreso</h1>
                <form onSubmit={login}>
                    <div className={styles.email}>
                        <MdEmail className={styles.icon} />
                        <input
                            type="email"
                            placeholder="usuario@correo.com"
                            autoFocus
                            onChange={changeEmailHandler}
                            required
                        />
                    </div>
                    <div className={styles.email}>
                        <MdLock className={styles.icon} />
                        <input type="password" placeholder="**********" onChange={changePasswordHandler} required/>
                    </div>
                    <button className={styles.button} type='submit'>Ingresar</button>
                    <div className={styles.options}>
                        <Link to="/register" className={styles.link}>Crear una cuenta</Link>
                        <Link to="/forgotPassword" className={styles.link}>Olvidé mi clave</Link>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default LoginPage;
